<template>
  <div class="container is-max-desktop has-text-centered px-4" style="height: 100%">
    <div
      class="columns is-marginless is-justify-content-center"
      style="height: 100%">
      <img width="300" src="@/assets/images/verify-user-account.svg">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { VerifyUserAccount, status } from '@/api/user';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['email', 'settings']),
  },
  methods: {
    ...mapMutations({
      setUser: 'SET_USER',
    }),
  },
  async mounted() {
    const loadingComponent = this.$buefy.loading.open();
    try {
      const userStatus = (await status()).data;
      if (!userStatus.admin) {
        this.$router.push({ name: 'Setup' });
        return;
      }
      if (this.settings.setup && this.settings.setup.completed) {
        const res = (await VerifyUserAccount(this.email)).data;
        loadingComponent.close();
        if (res.user) {
          this.setUser(res.user);
          this.$router.push({ name: 'Home' });
        } else this.$router.push({ name: 'CreateAccount' });
        return;
      }
      this.$router.push({ name: 'Setup' });
    } catch (error) {
      //
    } finally {
      loadingComponent.close();
    }
  },
};
</script>

<style>

</style>
